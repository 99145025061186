import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(marketing)": [6,[2]],
		"/app": [~11,[4]],
		"/app/api-keys": [12,[4]],
		"/app/pipelines": [13,[4]],
		"/app/pipelines/new": [16,[4]],
		"/app/pipelines/[id]": [~14,[4]],
		"/app/pipelines/[pipeline_id]/edit": [~15,[4]],
		"/app/profile": [17,[4]],
		"/app/teams": [~18,[4]],
		"/auth/sign-in": [19],
		"/auth/sign-up": [20],
		"/docs/embedding": [21],
		"/example-job": [22],
		"/(marketing)/old-home": [7,[2,3]],
		"/(marketing)/old-home/api-and-sdks": [8,[2,3]],
		"/(marketing)/old-home/pricing": [9,[2,3]],
		"/(marketing)/old-home/tours": [10,[2,3]],
		"/onboarding": [~23,[5]],
		"/payments/checkout": [~24],
		"/payments/pricing": [25],
		"/payments/success": [~26],
		"/sentry-example": [27]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';